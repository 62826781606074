// Modeled after:
// https://jtway.co/5-steps-to-add-remote-modals-to-your-rails-app-8c21213b4d0c

import { ReactRailsUJS } from "@/src/react"

const modal_holder_selector = "#js-modal-holder"
const modal_selector = ".js-modal"

function showModalWithContent(content) {
  hideAdditionalLinks()
  const modal_options = $(content).data("modal-options")
  onHideModal()
  $(".modal-backdrop").remove()
  $(modal_holder_selector)
    .html(content)
    .find(modal_selector)
    .modal(modal_options)
  applyGlobalJS($(modal_selector))
  ReactRailsUJS.mountComponents(modal_selector)
}

function hideAdditionalLinks() {
  if (window.application) {
    window.application
      .getControllerForElementAndIdentifier(
        document.querySelector("[data-controller*='sidebar']"),
        "sidebar"
      )
      .hideAdditionalLinks()
  }
}

function loadModal(event) {
  let element = this
  let location
  const hasStoredMinimizedModal =
    sessionStorage.getItem("minimizedModalJson") &&
    !(element.dataset.action || "").includes(
      "minimizable-modal#submitMinimizedModalForm"
    )
  if (hasStoredMinimizedModal) {
    $("#js-modal-holder")[0].innerHTML = ""
    const url = new URL(window.location.href)
    url.pathname = "modal_confirmation"
    url.searchParams.set("modal_action", element.text)
    url.searchParams.set("forward_path", element.href)
    location = url
  } else {
    location = $(this).attr("href")
  }
  event.preventDefault()
  loadModalFromUrl(location)
}

function loadModalFromSelect(event) {
  if (event.originalEvent) {
    console.log("modalHandler", "skip jquery wrapped event")
  } else {
    event.preventDefault()
    const location = this.dataset["href"]
    console.log(
      "modalHandler",
      "Load for select",
      event,
      this.name,
      this.value,
      location
    )
    const url = new URL(location)
    url.searchParams.append(this.name, this.value)
    loadModalFromUrl(url)
  }
}

function loadModalFromUrl(url) {
  $.get(url, data => {
    showModalWithContent(data)
  })
}

function responseIsAModal(event) {
  const [data, _status, _xhr] = event.detail
  return !(typeof data == "string")
}

function onResponse(event) {
  console.log("modalHandler", "Received response", event.detail)
  const [data, _status, xhr] = event.detail
  if (responseIsAModal(event)) {
    console.log("modalHandler", "Response is a modal. Replace modal content")
    // Update modal content
    const modal = $(data)
      .find("body")
      .html()
    showModalWithContent(modal)
  } else if (xhr.status == 202) {
    console.log("modalHandler", "Received 202 Accepted response. Do nothing")
  } else if (xhr.status == 500) {
    alert(
      "An error occurred. Please try again. \nIf the problem persists, please contact support."
    )
  } else {
    console.log("modalHandler", "Turbolinks automatically follows redirect")
    $(modal_selector).modal("hide")
  }
}

function onHideModal() {
  if (window.ReactRailsUJS) {
    ReactRailsUJS.unmountComponents(".js-modal [data-react-class]")
  }
}

$(document).on("change", "select[data-modal]", loadModalFromSelect)
$(document).on("click", "a[data-modal]", loadModal)
$(document)
  .on("ajax:success", "form[data-modal]", onResponse)
  .on("ajax:error", "form[data-modal]", onResponse)
$(document).on("hide.bs.modal", onHideModal)

window.showModalWithContent = showModalWithContent

function showModalAsLiveReloading() {
  document.querySelector(".js-modal-content").classList.add("__live-reloading")
}

export { showModalAsLiveReloading }
